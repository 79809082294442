
// lib
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'

// app
import { rules } from '@/app/shared/utils/validation'
import Section from '@/app/article/shared/models/Section'
import UiAdditionalDrawer from '@/app/shared/components/ui-additional-drawer.vue'
import SmartSelectSection from '@/app/article/shared/components/smart-select-section.vue'

@Component({
  components: {
    SmartSelectSection,
    UiAdditionalDrawer
  }
})
export default class AppSectionDrawer extends Vue {
  @PropSync('visible', {
    type: Boolean,
    default: false
  }) syncedVisible!: boolean

  @Prop({
    type: Object,
    required: true
  }) value!: Section

  @Prop({
    type: Boolean,
    default: false
  }) readonly withoutRequest!: boolean

  rules = rules

  found: { id: number | null, title: string }[] = [{
    id: null,
    title: 'Общий журнал'
  }]

  handleChangeField (field: string, value: any): void {
    this.$emit('change-field', { field, value })
  }

  handleChangeParent (value: number): void {
    this.handleChangeField('parent', { ...this.value.parent, id: value })
  }

  handleClickSave (): void {
    this.syncedVisible = false
    this.$emit('click-save')
  }
}
