
// lib
import { Vue, Component, VModel } from 'vue-property-decorator'

@Component
export default class AppAdditionalDrawer extends Vue {
  @VModel({
    type: Boolean,
    default: false
  }) drawer!: boolean

  items = [
    { title: 'Home', icon: 'mdi-view-dashboard' },
    { title: 'About', icon: 'mdi-forum' },
  ]

  handleSave (): void {
    const form = this.$refs.form as any
    const res = form.validate()

    if (res) {
      this.$emit('click-save')
    }
  }
}
