
// lib
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

// app
import AppSelectSection from './app-select-section.vue'
import Section from '../models/Section'

@Component({
  components: {
    AppSelectSection
  }
})
export default class SmartSelectSection extends Vue {
  @Prop({ required: true }) readonly value!: number

  @Prop({
    type: Array,
    default: () => []
  }) readonly rules!: []

  @Prop({
    type: Boolean,
    default: false
  }) readonly withoutRequest!: boolean

  @Prop({
    type: Boolean,
    default: false
  }) readonly withoutFetch!: boolean

  sectionSearch = null

  get currentSection (): Section | undefined {
    return this.sections.find(v => v.id === this.value)
  }

  get sections (): Section[] {
    return (this.$store.state.article as any).section.list
  }

  created (): void {
    if (!this.withoutRequest) {
      this.$store.dispatch('article/section/fetchList')
    }
  }

  @Watch('sectionSearch')
  handleChangeSearch (value: string): void {
    if (value !== this.currentSection?.title) {
      this.$emit('input-search', value)
      this.$emit('input', null)
    }
  }

  @Watch('value', { immediate: true })
  handleSelectSection (value: number): void {
    if (value && !this.withoutFetch) {
      this.$store.dispatch('article/section/fetch', value)
    }
  }
}
