export function generateUrlFromText (str: string): string {
  const ru = new Map([
    ['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'g'], ['д', 'd'], ['е', 'e'],
    ['є', 'e'], ['ё', 'e'], ['ж', 'j'], ['з', 'z'], ['и', 'i'], ['ї', 'yi'], ['й', 'i'],
    ['к', 'k'], ['л', 'l'], ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'p'], ['р', 'r'],
    ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'], ['х', 'h'], ['ц', 'c'], ['ч', 'ch'],
    ['ш', 'sh'], ['щ', 'shch'], ['ы', 'y'], ['э', 'e'], ['ю', 'u'], ['я', 'ya']
  ])

  str = str.trim()
  str = str.replace(/[^A-Za-zА-Яа-щыэюя0-9 ]+/g, '')
  str = str.replace(/[ ]+/g, '-')

  return Array.from(str)
    .reduce((s, l) =>
        s + (
          ru.get(l)
          || ru.get(l.toLowerCase()) === undefined && l.toLowerCase()
          || ru.get(l.toLowerCase())
        )
      , '')
}

export function isNumeric (str: string | number) {
  if (typeof str === 'number') {
    return true
  }

  return !isNaN(parseFloat(str))
}
