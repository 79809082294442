
// lib
import { Vue, Component, VModel, Prop, Watch } from 'vue-property-decorator'

// app
import { generateUrlFromText } from '@/app/shared/utils/string'
import Section from '../models/Section'
import AppSectionDrawer from './app-section-drawer.vue'

const initialValue = () => new Section({
  parent: {
    id: 1,
    title: 'Общий журнал'
  },
  title: '',
  link: '',
  metaTitle: '',
  metaDescription: '',
  microdata: '',
  isActive: 1,
  isSitemap: 0
})

@Component({
  components: {
    AppSectionDrawer
  }
})
export default class SmartSectionDrawer extends Vue {
  @VModel({
    type: Boolean,
    default: false
  }) visible!: boolean

  @Prop({
    type: String,
    default: ''
  }) readonly initialTitle!: string

  @Prop({
    type: Boolean,
    default: false
  }) readonly withEditing!: boolean

  @Prop({
    type: Boolean,
    default: false
  }) readonly withoutRequest!: boolean

  isUpdated = false

  formState: Section = initialValue()

  get sectionEntity (): Section {
    return (this.$store.state.article as any).section.entity
  }

  get form (): Section {
    if (this.withEditing) {
      return this.sectionEntity
    } else {
      return this.formState
    }
  }

  handleChangeField (data: { field: string, value: any }): void {
    if (data.field === 'title') {
      const link = generateUrlFromText(data.value)

      this.handleChangeField({ field: 'link', value: link })
    }

    if (this.withEditing) {
      this.$store.commit('article/section/updateField', { ...data })
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.formState[data.field] = data.value
    }
  }

  handleSave (): void {
    if (this.withEditing) {
      this.$store.dispatch('article/section/update', this.form)
    } else {
      this.$store.dispatch('article/section/create', this.form)
    }
  }

  @Watch('initialTitle')
  handleChangeTitle (value: string): void {
    if (value) {
      this.handleChangeField({ field: 'title', value: value })
    }
  }
}
