
// lib
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'

// app
import Section from '../models/Section'

@Component
export default class AppSelectSection extends Vue {
  @Prop({ required: true }) readonly value!: number

  @Prop({
    type: Array,
    default: () => []
  }) readonly rules!: []

  @Prop({
    type: Array,
    default: () => []
  }) readonly sections!: Section[]

  @PropSync('searchInput', { required: true }) syncedSearchInput!: string
}
